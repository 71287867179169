import React, { Component } from "react";
import "./css/Receipts.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "./Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import report_data from "./report_data";
import Form from "react-bootstrap/Form";

class Reports extends Component {
  constructor(props) {
    super(props);

    // Extract unique years and months from batch_data
    const years = new Set();
    const months = new Set();

    report_data.forEach((batch) => {
      const date = new Date(batch.date);
      years.add(date.getFullYear());
      months.add(date.getMonth() + 1); // getMonth() returns 0-11, add 1 to make it 1-12
    });

    // Convert sets to sorted arrays and format them
    const yearList = Array.from(years).sort((a, b) => b - a).map((year) => ({ name: year.toString(), value: year }));
    const monthList = Array.from(months).sort((a, b) => a - b).map((month) => ({
      name: new Date(0, month - 1).toLocaleString('default', { month: 'long' }),
      value: month
    }));

    // Add "All" option to yearList and monthList
    yearList.unshift({ name: "All", value: "all" });
    monthList.unshift({ name: "All", value: "all" });


    // Default to the latest year and month
    const defaultYear = yearList.length > 0 ? yearList[yearList.length - 1].value : "all";
    const defaultMonth = monthList.length > 0 ? monthList[monthList.length - 1].value : "all";


    this.state = {
      data: [],

      attendeeError: "",
      newSearch: {
        Identifier: "",
      },
      searchReceipt: {
        Receipt_ID: "",
        Receipt_Number: "",
        Amount: "",
        Name: "",
      },
      receiptInfo: null,
      receiptError: null,
      attendee: null,
      error: null,
      isLoading: false,
      screenWidth: 0,
      batches: report_data,
      filters: [
        { name: "All", value: "all" },
        { name: "Not Mailed yet", value: "unmailed" },
        { name: "Already Mailed", value: "mailed" },
      ],

      receiptType: "",
      receiptFilter: "all",
      intervalId: null, // Store the interval ID in state
      receiptList: [],
      searchList: [],
      notesText: "",
      expandedRowID: null,
      expandedRowType: null,
      yearList: yearList,
      monthList: monthList,
      year: defaultYear,
      month: defaultMonth,
      filterBatches: []
    };

  }

  async componentDidMount() {
    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.state.intervalId);
  }

  handleDownload = async (link, fileName) => {
    this.setState({ isLoading: true });
    try {
      console.log(link);
      const response = await fetch(link);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Failed to download the file");
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    const fieldId = e.target.id;

    this.setState({ [fieldId]: selectedValue });
  };

  filterBatches = () => {
    const { year, month, batches } = this.state;
    const filteredBatches = batches.filter((batch) => {
      const batchDate = new Date(batch.date);
      let matchYear = false;
      let matchMonth = false;

      if (year === "all") {
        matchYear = true;
      }
      else {
        matchYear = batchDate.getFullYear() === parseInt(year);
      }

      if (month === "all") {
        matchMonth = true;
      }
      else {
        matchMonth = batchDate.getMonth() + 1 === parseInt(month);
      }
      return matchYear && matchMonth;
    });

    return filteredBatches;
  };

  render() {
    const isIPad = this.state.screenWidth <= 1200;

    const {yearList, year, monthList, month} = this.state;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>General Reports</span>
            </h1>
            <div className="auth-wrap">
            <div className="auth-col">
              <form className="form-validate">
                
            <p className="contactform-field contactform-text">
                  <label className="contactform-label">Year Batched</label>
                  <span className="contactform-input">
                    <Form.Control
                      id="year"
                      as="select"
                      value={year}
                      onChange={this.handleOptionChange}
                    >
                      {yearList.map((year) => (
                        <option key={year.value} value={year.value}>
                          {year.name}
                        </option>
                      ))}
                    </Form.Control>
                  </span>
                </p>

                            <p className="contactform-field contactform-text">
                  <label className="contactform-label">Month Batched</label>
                  <span className="contactform-input">
                    <Form.Control
                      id="month"
                      as="select"
                      value={month}
                      onChange={this.handleOptionChange}
                    >
                      {monthList.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.name}
                        </option>
                      ))}
                    </Form.Control>
                  </span>
                </p>
                </form></div></div>


            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-ttl">Name</td>
                    <td className="cart-ttl">Date Last Updated</td>
                    <td className="cart-ttl">Description</td>
                    <td className="cart-ttl"></td>
                  </tr>
                </thead>

                <tbody>
                  {this.filterBatches().map((batch, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{batch.name}</td>
                        <td>{batch.date}</td>
                        <td dangerouslySetInnerHTML={{ __html: batch?.description }}></td>
                        <td>
                          <button
                            onClick={() =>
                              this.handleDownload(
                                batch.link,
                                batch.filename
                              )
                            }
                          >
                            Download
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <ToastContainer />
          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default Reports;
import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Add Quill styles

class AdditionalNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const editorStyle = {
      height: "200px", // Adjust this value to increase the height
    };

    const buttonStyle = {
      float: "right", // Align the button to the right
      // Add any other styles you need for the button
    };

    const containerStyle = {
      overflow: "hidden", // Clear floats in the container
    };

    return (
      <>
        <p
          className="contactform-field contactform-textarea"
          style={{ height: "250px" }}
        >
          <span className="contactform-input">
            <ReactQuill
              style={editorStyle}
              value={this.props.text}
              onChange={this.props.handleChange}
            />
          </span>
        </p>
        <div style={containerStyle}>
          <button
            style={buttonStyle}
            onClick={(e) => this.props.addNote(e, this.props.receiptID)}
            disabled={this.state.isLoading}
          >
            Add Note
          </button>
        </div>
      </>
    );
  }
}

export default AdditionalNotes;

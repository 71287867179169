import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes as faXmark } from "@fortawesome/free-solid-svg-icons";

class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = (dateString) => {
    const date = new Date(dateString + 'Z');
    const options = {
      timeZone: 'America/Los_Angeles', // Pacific Time Zone
      hour12: true, // Use 12-hour clock format
      weekday: 'long', // Full day of the week
      year: 'numeric', // Full year
      month: 'long', // Full month name
      day: 'numeric', // Day of the month
      hour: 'numeric', // Hour (0-23)
      minute: 'numeric', // Minute
      second: 'numeric' // Second
    };
    return date.toLocaleString('en-US', options);
  };
  

  render() {
    const {
      transactionList,
      title,
    } = this.props;

    return (
      <section className="container stylization maincont">
        <h1 className="main-ttl">
          <span>{title}</span>
        </h1>
        <div className="cart-items-wrap">
          <table className="cart-items">
          <thead>
  <tr>
    <td className="cart-ttl">Date / Contact</td>
    <td className="cart-ttl">Amount / Fees</td>
    <td className="cart-ttl">Payment Method / Payment Type</td>
    <td className="cart-ttl">Monthly / Pay Fees? </td>
    <td className="cart-ttl">What is this for?</td>
  </tr>
</thead>


            <tbody>
              {transactionList.map((transaction, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                    {this.formatDate(transaction.createDate)}


                    <br /> <br />
                    <strong>{transaction.firstName} {transaction.lastName}</strong><br/>

                      {transaction.addressLine1} <br />
                      {transaction.addressLine2 && (
                        <>
                          {transaction.addressLine2}
                          <br />
                        </>
                      )}
                      {transaction.city}, {transaction.province}  {transaction.zip}<br />
                      {transaction.email}<br />
                      {transaction.phoneNumber}

                      <br/>
                      {!transaction.billingAddress && transaction.billingAddressLine1 && (
                        <>
                        [Billing Addresss]
                        {transaction.billingAddressLine1} <br />
                      {transaction.billingAddressLine2 && (
                        <>
                          {transaction.billingAddressLine2}
                          <br />
                        </>
                      )}
                      {transaction.billingCity}, {transaction.billingProvince} {transaction.billingZip}
                        </>
                      )}
                    </td>
                    <td>
                    ${(transaction.donationAmount / 100).toFixed(2)}<br /><br />
                    ${((transaction.stripeFees + transaction.ourFees) / 100).toFixed(2)}
                    </td>
                    <td  className="cart-del">{transaction.paymentMethod}<br/><br/>{transaction.paymentCard}</td>
                    <td  className="cart-del">{transaction.monthly ? (
                        "Monthly"
                      ) : (
                        "Not monthly"
                      )}<br /><br />
                      {!transaction.payFees ? (
                        "Donor does not pay fees"
                      ) : (
                        "Donor pay fees"
                      )}</td>
                      <td  className="cart-del">{transaction.originURL === "https://tickets.bigsisters.bc.ca/" ? "GrapeJuice 2024" : transaction.originURL}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default TransactionList;
import "./css/App.css";
import NotFound from "./NotFound";
import Receipts from "./Receipts"
import Transactions from "./Transactions";
import Admin from "./Admin";
import { Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Batches from "./Batches";
import Secret from "./Secret";
import Auth from "./Auth";
import Reports from "./Reports";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Transactions />} />
        <Route path="/receipts" element={<Receipts />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/batches" element={<Batches />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/secret" element={<Secret />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
import React, { Component } from "react";
import "./css/Receipts.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "./Spinner";
import axios from "axios";
import * as PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import JSZip from "jszip";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReceiptList from "./ReceiptList";
import RECEIPTS from './receipt-categories';

class Admin extends Component {
  constructor(props) {
    super(props);

    const filteredReceipts = RECEIPTS.filter(receipt => !receipt.secret);

    this.state = {
      data: [],

      attendeeError: "",
      newSearch: {
        Identifier: "",
      },
      searchReceipt: {
        Receipt_ID: "",
        Receipt_Number: "",
        Amount: "",
        Name: "",
      },
      receiptInfo: null,
      receiptError: null,
      attendee: null,
      error: null,
      isLoading: false,
      screenWidth: 0,
      categories: filteredReceipts,
      filters: [
        { name: "All", value: "all" },
        { name: "Not Mailed yet", value: "unmailed" },
        { name: "Already Mailed", value: "mailed" }
      ],

      receiptType: "",
      receiptFilter: "all",
      intervalId: null, // Store the interval ID in state
      receiptList: [],
      searchList: [],
      notesText: "",
      expandedRowID: null,
      expandedRowType: null,
    };
  }

  async componentDidMount() {
    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.state.intervalId);
  }

  getFormattedMonthlyDate = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = 7; // Set the day to 18 for November 18
    const month = 2; // November is at index 10 (zero-based)
    const year = 2024; // Set the year to 2023

    const formattedDate = `${months[month]} ${day}${getOrdinalSuffix(
      day
    )}, ${year}`;

    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return formattedDate;
  };


  getFormattedDate = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();

    const formattedDate = `${months[month]} ${day}${getOrdinalSuffix(
      day
    )}, ${year}`;

    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return formattedDate;
  };

  getCustomFormattedDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    console.log(date);

    const today = new Date(date);
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();

    const formattedDate = `${months[month]} ${day}${getOrdinalSuffix(
      day
    )}, ${year}`;

    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return formattedDate;
  };

  formatNumberAsCurrency = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  performMailMerge = () => {
    axios.get("template4.docx", { responseType: "arraybuffer" })
    .then((response4) => {
      axios.get("monthly_reissue_template.docx", { responseType: "arraybuffer" })
      .then((monthly_reissue_response) => {
        axios
          .get("gj2024_fan_template.docx", { responseType: "arraybuffer" })
          .then((gj2024_fan_template) => {
        axios
          .get("template3.docx", { responseType: "arraybuffer" })
          .then((response3) => {
            axios
              .get("template2.docx", { responseType: "arraybuffer" })
              .then((response) => {
                axios
                  .get("reissue_template.docx", { responseType: "arraybuffer" })
                  .then((response2) => {
                    // Create a new instance of JSZip
                    const zip = new JSZip();

                    this.state.data.forEach((record, index) => {
                      let zipTemplate;

                      if (record["Appeal_Description_1"] === "Monthly Donors") {
                        if (record["mailedDate"]) {
                          zipTemplate = new PizZip(
                            monthly_reissue_response.data
                          );
                        } else {
                          zipTemplate = new PizZip(response3.data);
                        }
                      } else if (record["Business"] == "Acknowledgement" && record["ID"] === 1359) {
                        zipTemplate = new PizZip(response4.data);

                      } 
                      else if (record["mailedDate"]) {
                        zipTemplate = new PizZip(response2.data);
                      } else {
                        zipTemplate = new PizZip(response2.data);
                      }

                      if (record["Reference"].includes("GrapeJuice 2024")) {
                        zipTemplate = new PizZip(gj2024_fan_template.data);
                      }

                      // Create a new instance of doc for each data record
                      let currentDoc = new Docxtemplater(zipTemplate, {
                        linebreaks: true,
                      });

                      let my_data = { ...record };

                      let identifier = my_data["ID"];

                      let appeal = my_data["Appeal_Description_1"];

                      if (!my_data["Todays_Date"]) {
                        if (appeal.includes("LAS")) {
                          my_data["Todays_Date"] = this.getFormattedDate();
                        } else if (
                          record["Appeal_Description_1"] === "Monthly Donors"
                        ) {
                          my_data["Todays_Date"] =
                            this.getFormattedMonthlyDate();
                        } else {
                          my_data["Todays_Date"] = this.getFormattedDate();
                        }
                      } else {
                        my_data["Todays_Date"] = this.getCustomFormattedDate(
                          my_data["Todays_Date"]
                        );
                      }

                      if (my_data["Business"] == "Business") {
                        my_data["Charitable"] =
                          "This is a business receipt, not a charitable tax receipt.";
                      } else if (my_data["Business"] == "Acknowledgement") {
                        my_data["Charitable"] =
                          "This is an official acknowledgement receipt.";
                      }
                      else {
                        my_data["Charitable"] = "Official Donation Receipt For Income Tax Purposes.";
                      }

                      let address = "";

                      if (my_data["Address_Line_1"]) {
                        address += `${my_data["Address_Line_1"]}\n`;
                      }
                      if (my_data["Address_Line_2"]) {
                        address += `${my_data["Address_Line_2"]}\n`;
                      }

                      if (my_data["Address_Line_3"]) {
                        address += `${my_data["Address_Line_3"]}\n`;
                      }

                      if (my_data["Address_Line_4"]) {
                        address += `${my_data["Address_Line_4"]}\n`;
                      }

                      if (my_data["Address_Line_5"]) {
                        address += `${my_data["Address_Line_5"]}\n`;
                      }

                      let org_name = "";

                      if (my_data["Organization_Name"]) {
                        org_name = `${my_data["Organization_Name"]}\n`;
                      }

                      let city = my_data["City"];

                      if (city !== "") {
                        city = `${city},`;
                      }

                      console.log(city);

                      if (address === "" && my_data["Phone_Number"]) {
                        let phone_number = my_data["Phone_Number"];
                        if (my_data["Addressee"] === my_data["Organization_Name"]) {
                          my_data[
                            "Mailing_Address"
                          ] = `${my_data["Addressee"]}\n${phone_number}`;
                        } else {
                          my_data[
                            "Mailing_Address"
                          ] = `${my_data["Addressee"]}\n${org_name}${phone_number}`;
                        }

                      } else {
                        if (my_data["Addressee"] === my_data["Organization_Name"]) {
                          my_data[
                            "Mailing_Address"
                          ] = `${my_data["Addressee"]}\n${address}${city} ${my_data["Province"]}  ${my_data["Postal_Code"]}`;
                        } else {
                          my_data[
                            "Mailing_Address"
                          ] = `${my_data["Addressee"]}\n${org_name}${address}${city} ${my_data["Province"]}  ${my_data["Postal_Code"]}`;
   
                        }
                      }

                      console.log(my_data["Mailing_Address"]);

                      console.log(my_data["Business"])

                      if (my_data["Business"] == "Business" || my_data["Business"] == "Acknowledgement") {
                        if (org_name) {
                          my_data["Receipt_Address"] = `${org_name}${address}${city} ${my_data["Province"]}  ${my_data["Postal_Code"]}`;
                        } else {
                          my_data["Receipt_Address"] = `${my_data["Addressee"]}\n${address}${city} ${my_data["Province"]}  ${my_data["Postal_Code"]}`;
                        }
                      }
                      else {
                        if (my_data["ID"] === 1366) {
                          my_data["Receipt_Address"] = `${my_data["Addressee"]}\n${my_data["Address_Line_2"]}\n${my_data["Address_Line_3"]}\n${city} ${my_data["Province"]}  ${my_data["Postal_Code"]}`;
                        } else {
                          my_data["Receipt_Address"] = my_data["Mailing_Address"];
                        }
                        


                      }

                      console.log(my_data["Receipt_Address"]);

                      my_data["Amount"] = this.formatNumberAsCurrency(
                        my_data["Amount"]
                      );
                      my_data["Receipt_Amount"] = this.formatNumberAsCurrency(
                        my_data["Receipt_Amount"]
                      );

                      if (appeal.includes("LAS")) {
                        my_data["Reference"] =
                          "Soirée 2023\n" + my_data["Reference"];
                      } else if (appeal.includes("Giving Tues")) {
                        my_data["Reference"] =
                          "Giving Tuesday 2023\n" + my_data["Reference"];
                      } else if (appeal.includes("2023 Spring Lunch")) {
                        my_data["Reference"] =
                          "Spring Lunch 2023\n" + my_data["Reference"];
                      } else if (appeal.includes("2023 GrapeJuice")) {
                        my_data["Reference"] =
                          "GrapeJuice 2023\n" + my_data["Reference"];
                      }

                      console.log(
                        "Mailing_Address after rendering:",
                        my_data["Mailing_Address"]
                      );

                      // Perform mail merge by passing the data for the current record
                      currentDoc.setData(my_data);

                      try {
                        // Render the document with merged data
                        currentDoc.render();
                        const updatedDocxData = currentDoc
                          .getZip()
                          .generate({ type: "blob" });

                        const sanitizedOrgName = my_data[
                          "Organization_Name"
                        ].replace(/\//g, ""); // Remove slashes
                        const sanitizedAddressee = my_data["Addressee"].replace(
                          /\//g,
                          ""
                        ); // Remove slashes

                        // Add the merged document to the zip
                        const filename = `${identifier}_${sanitizedOrgName}_${sanitizedAddressee}.docx`;

                        zip.file(filename, updatedDocxData);
                      } catch (error) {
                        console.error("Error during mail merge:", error);
                      }
                    });

                    // Generate the zip file with all merged documents
                    zip
                      .generateAsync({ type: "blob" })
                      .then((content) => {
                        this.uploadZip(content, "receipts.zip");
                      })
                      .catch((error) => {
                        console.error("Error generating the zip:", error);
                      });
                  });
              });
          });
        });
      });
    })
      .catch((error) => {
        console.error("Error loading template.docx:", error);
      });
  };

  getPresignedUrl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/receipt-download`
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching presigned URL:", error);
      throw error;
    }
  };

  checkPDF = (filenameWithoutExtension) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/receipt-download/${filenameWithoutExtension}`,
    }).then((response) => {
      const result = response.data;
      console.log(result);

      if (result["url"] && result["url"] !== "") {
        console.log("Downloading URL Now!");
        this.downloadZip(result["url"]);
      } else {
        // If the url is empty, continue checking
        console.log("URL is empty. Will check again in 20 seconds.");
      }
    });
  };

  uploadZip = async (zipData, filename) => {
    try {
      const presignedData = await this.getPresignedUrl();
      console.log(presignedData);
      const { url, fields } = presignedData;

      const formData = new FormData();
      let entries = Object.entries(fields);

      let myKey = "";

      entries.map(([key, val]) => {
        console.log(key);
        if (key === "key") {
          myKey = val;
        }
        formData.append(key, val);
      });

      const filenameWithoutExtension = myKey.replace(".zip", "");

      formData.append("file", zipData);

      axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(() => {
        // Define a separate function for setInterval
        const startCheckPDFInterval = () => {
          const intervalId = setInterval(() => {
            this.checkPDF(filenameWithoutExtension);
          }, 20000);
          this.setState({ intervalId });
        };

        // Call the function that starts the interval
        startCheckPDFInterval();
      });

      // Handle success as needed
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error as needed
    }
  };

  downloadZip = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = "Receipts.pdf";
    a.click();
    clearInterval(this.state.intervalId);
    this.setState({ isLoading: false });
  };

  downloadReceipt = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    if (!this.state.receiptType) {
      this.setState({ error: "Please set a receipt", isLoading: false });
      return;
    }

    this.setState({ error: "" });

    try {
      // Make an API request to get JSON data
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/receipts/${this.state.receiptType}`
        )
        .then((response) => {
          console.log("From api call: ");
          console.log(response.data);

          let filteredReceipts = this.filterReceipts(response.data);

          this.setState({ data: filteredReceipts }, () => {
            this.performMailMerge();
          });
        })
        .catch((error) => {
          console.error("Error fetching data from the API:", error);
        });
    } catch (error) {
      this.setState({ error: `Error downloading receipt: ${error.message}` });
      console.error("Error downloading receipt:", error);
    }
  };

  downloadSingleReceipt = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    if (!this.state.receiptInfo) {
      this.setState({ error: "Please set a receipt", isLoading: false });
      return;
    }

    this.setState({ error: "" });

    try {
      this.setState({ data: [this.state.receiptInfo] }, () => {
        this.performMailMerge();
      });
    } catch (error) {
      this.setState({ error: `Error downloading receipt: ${error.message}` });
      console.error("Error downloading receipt:", error);
    }
  };

  toCamelCaseName = (inputName) => {
    if (typeof inputName === "string" && inputName.length > 0) {
      // Split the input name into words using spaces
      const words = inputName.split(" ");

      // Capitalize the first letter of each word and convert the rest to lowercase
      const camelCaseName = words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");

      return camelCaseName;
    } else {
      return ""; // or handle the case of an empty or undefined inputName as needed
    }
  };

  handleIdentifierChange = (e) => {
    this.setState({
      newSearch: { ...this.state.newSearch, Identifier: e.target.value },
    });
  };

  searchForAttendee = async () => {
    try {
      if (!this.state.newSearch.Identifier) {
        return;
      }

      // Call fetchAttendees to fetch attendees
      const attendees = await this.fetchAttendees();

      const existingAttendee = attendees.find((attendee) =>
        attendee["Name"].toString().includes(this.state.newSearch.Identifier)
      );

      console.log(existingAttendee);

      if (existingAttendee) {
        this.setState({ attendee: existingAttendee, attendeeError: "" });
      } else {
        this.setState({
          attendee: null,
          attendeeError:
            "Unable to locate an attendee based the Identifier. Please check the name specified in the Indentifier Field",
        });
      }

      // Add your logic here to search for an attendee within this.state.attendees
    } catch (error) {
      this.setState({
        attendeeError:
          "Issues loading attendees. Refresh the page and try again.",
      });
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  handleReceiptChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ receiptType: selectedValue });

    console.log(selectedValue);
  };

  handleFilterChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ receiptFilter: selectedValue });

    console.log(selectedValue);
  };

  handleReceiptIDChange = (e) => {
    this.setState({
      searchReceipt: {
        ...this.state.searchReceipt,
        Receipt_ID: e.target.value,
      },
    });
  };

  handleReceiptNumberChange = (e) => {
    this.setState({
      searchReceipt: {
        ...this.state.searchReceipt,
        Receipt_Number: e.target.value,
      },
    });
  };

  handleAmountChange = (e) => {
    this.setState({
      searchReceipt: { ...this.state.searchReceipt, Amount: e.target.value },
    });
  };

  handleNameChange = (e) => {
    this.setState({
      searchReceipt: { ...this.state.searchReceipt, Name: e.target.value },
    });
  };

  searchForReceipt = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { Receipt_ID, Receipt_Number, Amount, Name } =
      this.state.searchReceipt;

    // Call the API endpoint to search for receipts
    fetch(`${process.env.REACT_APP_API_URL}/receipt-search`, {
      method: "POST",
      body: JSON.stringify({
        Receipt_ID,
        Receipt_Number,
        Amount,
        Name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        let filteredReceipts = data.filter((receipt) => !receipt.isDeleted);

        if (filteredReceipts.length == 0) {
          this.setState({
            receiptError:
              "Oops, you’ve hit a snag in your search. The receipt is pending, please check back in a little bit for the final generation of this receipt.",
            receiptInfo: null,
            searchList: [],
            isLoading: false,
          });
        } else {
          this.setState(
            {
              receiptError: "",
              searchList: filteredReceipts,
              receiptInfo: null,
              isLoading: false,
            },
            () => {
              console.log(filteredReceipts);
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          receiptError: "Error fetching receipt information. Please try again.",
          receiptInfo: null,
          isLoading: false,
        });
        console.error("There was an error!", error);
      });
  };

  triggerReceipt = async (e, Receipt_ID) => {
    // Call the API endpoint to search for receipts
    fetch(`${process.env.REACT_APP_API_URL}/receipt-search`, {
      method: "POST",
      body: JSON.stringify({
        Receipt_ID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length == 1) {
          this.setState(
            {
              receiptInfo: data[0],
              receiptError: null,
            },
            async () => {
              await this.downloadSingleReceipt(e);
              return;
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          receiptError: "Error fetching receipt information. Please try again.",
          receiptInfo: null,
        });
        console.error("There was an error!", error);
      });
  };

  triggerDownloadSingleReceipt = async (e, Receipt_ID) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    await this.triggerReceipt(e, Receipt_ID);
    this.setState({ isLoading: false });
  };

  receiptMailed = async (e, receipt_ID) => {
    e.preventDefault();

    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    const records = [{ receiptID: receipt_ID, mailedDate: today }];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/record-mail-dates`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ records }),
        }
      );

      if (response.ok) {
        toast.success(
          `Receipt with ID ${receipt_ID} has been marked as mailed.`
        );
        this.fetchReceiptData();
      } else {
        throw new Error("Failed to mark receipt as mailed.");
      }
    } catch (error) {
      toast.error("Error marking receipt as mailed:", error.message);
      // Handle errors or display error messages to the user
    }
  };

  updateReceipt = (e) => {
    e.preventDefault();

    const {
      ID,
      Salutation,
      Addressee,
      Organization_Name,
      Address_Line_1,
      Address_Line_2,
      Address_Line_3,
      Address_Line_4,
      Address_Line_5,
      City,
      Province,
      Postal_Code,
      Reference,
      Todays_Date,
    } = this.state.receiptInfo;

    fetch(`${process.env.REACT_APP_API_URL}/receipts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ID,
        Salutation,
        Addressee,
        Organization_Name,
        Address_Line_1,
        Address_Line_2,
        Address_Line_3,
        Address_Line_4,
        Address_Line_5,
        City,
        Province,
        Postal_Code,
        Reference,
        Todays_Date,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle success, if needed
        toast.success("Update successful:", data);

        this.setState({ expandedRowID: null });

        if (this.state.receiptList.length > 0) {
          this.listReceipts(e);
        }

        if (this.state.searchList.length > 0) {
          this.searchForReceipt(e);
        }
      })
      .catch((error) => {
        // Handle error
        toast.error("There was an error updating the receipt:", error);
      });
  };

  filterReceipts = (data) => {
    let filteredReceipts = data;
    console.log(filteredReceipts);

    filteredReceipts = data.filter((receipt) => !receipt.isDeleted);

    if (this.state.receiptFilter === "mailed") {
      // Filter for receipts where isMailed is true
      filteredReceipts = data.filter((receipt) => receipt.mailedDate);
    } else if (this.state.receiptFilter === "unmailed") {
      // Filter for receipts where isMailed is false
      filteredReceipts = data.filter((receipt) => !receipt.mailedDate);
    }

    return filteredReceipts;
  };

  fetchReceiptData = () => {
    try {
      // Make an API request to get JSON data
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/receipts/${this.state.receiptType}`
        )
        .then((response) => {
          console.log("From api call: ");
          console.log(response.data);

          let filteredReceipts = this.filterReceipts(response.data);

          this.setState({ receiptList: filteredReceipts, isLoading: false });
        })
        .catch((error) => {
          console.error("Error fetching data from the API:", error);
        });
    } catch (error) {
      this.setState({ error: `Error downloading receipt: ${error.message}` });
      console.error("Error downloading receipt:", error);
    }
  };

  listReceipts = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    if (!this.state.receiptType) {
      this.setState({ error: "Please set a receipt", isLoading: false });
      return;
    }

    this.setState({ error: "" });

    this.fetchReceiptData();
  };

  updateNotes = (value) => {
    this.setState({ notesText: value });
  };

  updateExpandRow = (e, receipt_ID, type) => {
    e.preventDefault();

    if (type === "Receipt") {
      // Assuming this.state.receiptList and this.state.searchList are arrays of items
      const itemListToSearch = [
        ...this.state.receiptList,
        ...this.state.searchList,
      ];

      // Find the item with matching receiptID
      const foundItem = itemListToSearch.find((item) => item.ID === receipt_ID);

      if (foundItem) {
        // Update receiptInfo with the found item
        this.setState({
          expandedRowID: receipt_ID,
          expandedRowType: type,
          receiptInfo: foundItem,
        });
      }
    } else {
      this.setState({
        expandedRowID: receipt_ID,
        expandedRowType: type,
      });
    }
  };

  addNote = async (e, receipt_ID) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const records = [{ receiptID: receipt_ID, note: this.state.notesText }];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/record-mail-notes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ records }),
        }
      );

      if (response.ok) {
        toast.success(`Note added for Receipt ${receipt_ID}.`);
        this.fetchReceiptData();
      } else {
        throw new Error("Failed to add note.");
      }
    } catch (error) {
      toast.error("Error add note:", error.message);
      // Handle errors or display error messages to the user
    } finally {
      this.setState({ isLoading: false, expandedRowID: null, notesText: "" });
    }
  };

  handleReceiptInfoChange = (fieldName, value) => {
    this.setState({
      receiptInfo: {
        ...this.state.receiptInfo,
        [fieldName]: value,
      },
    });
  };

  render() {
    const isIPad = this.state.screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Update a Receipt</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.searchForReceipt} className="login">
                  <p>
                    <label htmlFor="Receipt_ID">Receipt ID:</label>
                    <input
                      type="text"
                      id="Receipt_ID"
                      name="Receipt_ID"
                      value={this.state.searchReceipt.Receipt_ID}
                      onChange={this.handleReceiptIDChange}
                      placeholder="Receipt ID"
                    />
                  </p>
                  <p>
                    <label htmlFor="Receipt_Number">Receipt Number:</label>
                    <input
                      type="text"
                      id="Receipt_Number"
                      name="Receipt_Number"
                      value={this.state.searchReceipt.Receipt_Number}
                      onChange={this.handleReceiptNumberChange}
                      placeholder="Receipt Number"
                    />
                  </p>
                  <p>
                    <label htmlFor="Amount">Amount:</label>
                    <input
                      type="text"
                      id="Amount"
                      name="Amount"
                      value={this.state.searchReceipt.Amount}
                      onChange={this.handleAmountChange}
                      placeholder="Amount"
                    />
                  </p>
                  <p>
                    <label htmlFor="Name">Addressee:</label>
                    <input
                      type="text"
                      id="Name"
                      name="Name"
                      value={this.state.searchReceipt.Name}
                      onChange={this.handleNameChange}
                      placeholder="Name"
                    />
                  </p>
                  <p className="auth-submit">
                    <input
                      type="submit"
                      value="Search Receipts"
                      disabled={this.state.isLoading}
                    />
                  </p>
                </form>
                {this.state.receiptError && (
                  <div className="err404">
                    <p className="err404-search">{this.state.receiptError}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <ReceiptList
            expandedRowID={this.state.expandedRowID}
            notesText={this.state.notesText}
            receiptList={this.state.searchList}
            updateNotes={this.updateNotes}
            addNote={this.addNote}
            updateExpandRow={this.updateExpandRow}
            triggerDownloadSingleReceipt={this.triggerDownloadSingleReceipt}
            receiptMailed={this.receiptMailed}
            title="Search Results"
            expandedRowType={this.state.expandedRowType}
            receiptInfo={this.state.receiptInfo}
            isLoading={this.state.isLoading}
            attendeeError={this.state.attendeeError}
            updateReceipt={this.updateReceipt}
            handleReceiptInfoChange={this.handleReceiptInfoChange}
          />

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Get Receipts</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">
                      Receipt Categories
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.receiptType}
                        onChange={this.handleReceiptChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.categories.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>


                  <p>
                    <button
                      onClick={this.downloadReceipt}
                      disabled={this.state.isLoading}
                    >
                      Download Receipts
                    </button>
                  </p>

                  <p>
                    <button
                      onClick={this.listReceipts}
                      disabled={this.state.isLoading}
                    >
                      List Receipts
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <ReceiptList
            expandedRowID={this.state.expandedRowID}
            notesText={this.state.notesText}
            receiptList={this.state.receiptList}
            updateNotes={this.updateNotes}
            addNote={this.addNote}
            updateExpandRow={this.updateExpandRow}
            triggerDownloadSingleReceipt={this.triggerDownloadSingleReceipt}
            receiptMailed={this.receiptMailed}
            title="List of Receipts"
            expandedRowType={this.state.expandedRowType}
            receiptInfo={this.state.receiptInfo}
            isLoading={this.state.isLoading}
            attendeeError={this.state.attendeeError}
            updateReceipt={this.updateReceipt}
            handleReceiptInfoChange={this.handleReceiptInfoChange}
          />
          <ToastContainer />
          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default Admin;
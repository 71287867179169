import axios from "axios";


const generateLink = (fileName) => {
    // Convert the file name to a format suitable for the link
    const formattedFileName = fileName.replace(/\s+/g, "%20");

    // Generate the link using template literals
    const link = `https://batch-reports-pdf.s3.amazonaws.com/${formattedFileName}.pdf`;

    return link;
  };

const get_batch_data = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/batch-data`
    );

    const batches =  response.data;
    batches.reverse();

    return batches;

  } catch (error) {
    console.log(error.message);
  }
};

const batch_data = await get_batch_data();

export default batch_data;
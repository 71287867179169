import React, { Component } from "react";
import "./css/Transactions.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "./Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransactionList from "./TransactionList";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
const moment = require("moment");

class Transactions extends Component {
  constructor(props) {
    super(props);

    const startDate = moment("2024-03-08"); // Start date: March 8, 2024
    const today = moment(); // Today's date

    const payoutIDList = ["Virtual Terminal", "Monthly", "2024-03-07"];

    // Loop from start date to today's date
    for (
      let date = startDate;
      date.isSameOrBefore(today, "day");
      date.add(1, "day")
    ) {
      // Check if the current date is a Friday
      if (date.day() === 5) {
        // 5 represents Friday (0 is Sunday, 6 is Saturday)
        payoutIDList.push(date.format("YYYY-MM-DD"));
      }
    }

    payoutIDList.reverse();

    // Check if the first element of payoutIDList is today's date, if not, append the first future Friday to the top of the list
    if (moment(payoutIDList[0]).isBefore(today, "day")) {
      const nextFriday = today.clone().endOf("isoWeek").add(1, "day");
      payoutIDList.unshift(nextFriday.format("YYYY-MM-DD"));
    }

    this.state = {
      data: [], // Modify state properties as needed for transactions

      // Add state properties specific to Transactions class
      fromDate: "",
      toDate: "",
      originURL: "",
      selectedPayoutID: "",
      payoutIDs: [], // Add payout ID options
      isLoading: false,
      error: null,
      originURLs: [],
      filteredData: [],
      setData: [],
      payoutIDList: payoutIDList,
      selectedPayoutID: payoutIDList[0],
      payoutData: [],
      // Add other necessary state properties
    };
  }

  // Lifecycle method called after the component has been mounted
  componentDidMount() {
    // Call fetchTransactions function when the component is mounted
    this.fetchTransactions();
  }

  convertToDollars(amount) {
    // Format the amount to a currency string
    const currencyString = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount / 100);

    // Remove the currency symbol to get the number
    const numberString = currencyString.replace(/[^0-9.-]/g, "");

    // Convert the number string to a number
    return parseFloat(numberString);
  }

  mapDonation = (donation) => {
    let manager = "";
    if (donation.originURL.includes("cobs_panorama")) {
      manager = "Marley";
    } else if (donation.originURL.includes("mjibril")) {
      manager = "Nathania";
    }

    return {
      "Checkout ID": donation.ID,
      "Date & Time": donation.createDate,
      "Relationship Manager": manager,
      "First Name": donation.firstName,
      "Middle Name": "",
      "Last Name": donation.lastName,
      "Gross Paid Amount": this.convertToDollars(donation.donationAmount),
      "Total Fee": this.convertToDollars(
        donation.stripeFees + donation.ourFees
      ),
      "Net Total": this.convertToDollars(donation.depositedToBigSisters),
      "Refund Amount": 0,
      "Refund Type": "", // No refund type provided in original data
      "Total Tax Receiptable Amount": this.convertToDollars(
        donation.donationAmount
      ), // No tax receiptable amount provided in original data
      "Tax Receipt Number": donation.ReceiptNumber, // No tax receipt number provided in original data
      "Fee Paid by Donor": donation.payFees
        ? this.convertToDollars(donation.stripeFees + donation.ourFees)
        : 0, // No fee paid by donor provided in original data
      "Payment Card": donation.paymentCard, // Assuming payment method is always Stripe
      "Payment Method": donation.paymentMethod
        ? donation.paymentMethod
        : "Credit Card", // Assuming payment method is always Stripe
      "Donation URL": donation.originURL,
      Email: donation.email,
      "Phone Number": donation.phoneNumber,
      "Company Name": donation.organizationName,
      "Address Line 1": donation.addressLine1,
      "Address Line 2": donation.addressLine2,
      City: donation.city,
      Province: donation.province,
      "Postal Code": donation.zip,
      Country: donation.country,
      Campaign: donation.campaign ? donation.campaign : "Unassigned",
      Appeal: donation.appeal ? donation.appeal : "Third Party 2024",
      Package: donation.package ? donation.package : "OLX",
      Fund: donation.fund ? donation.fund : "Unrestricted",
    };
  };

  // Fetch transaction data from the API
  fetchTransactions = async () => {
    try {
      this.setState({ isLoading: true });
      // Make API call to fetch transaction data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/transactions`
      );

      const records = response.data.map((transaction) => {
        if (!transaction.paymentMethod) {
          transaction.paymentMethod = "Credit Card";
        } else if (transaction.paymentMethod === "apple-pay") {
          transaction.paymentMethod = "Apple Pay";
        }
        return transaction;
      });

      const originURLs = [
        ...new Set(
          response.data
            .map((transaction) => {
              // Parse URL and extract part before '?'
              const urlParts = transaction.originURL.split("?");
              return urlParts[0];
            })
            .filter((url) => !url.includes("localhost") && url)
        ),
      ];

      // Update state with fetched data
      this.setState({
        data: records,
        originURLs: originURLs,
        isLoading: false,
      });
    } catch (error) {
      // Handle errors
      this.setState({ error: error.message, isLoading: false });
    }
  };

  // Handle changes in the date range
  handleFromDateChange = (value) => {
    this.setState({ fromDate: value });
  };

  handleToDateChange = (value) => {
    this.setState({ toDate: value });
  };

  // Handle changes in the origin URL dropdown
  handleOriginURLChange = (event) => {
    this.setState({ originURL: event.target.value });
  };

  downloadTransactions = (e) => {
    e.preventDefault();
    let donations = this.state.filteredData;
    if (this.state.filteredData.length === 0) {
      // Perform validation for required fields (date range and origin URL)
      if (!this.state.fromDate || !this.state.toDate || !this.state.originURL) {
        this.setState({ error: "Please fill in all required fields" });
        return;
      }

      // Adjust the end date to include the full range of the selected day
      const endDate = new Date(this.state.toDate);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);

      // Filter transactions based on criteria
      donations = this.state.data.filter((transaction) => {
        // Check if transaction's createDate falls within the adjusted date range
        const transactionDate = new Date(transaction.createDate);

        const fromDate = new Date(this.state.fromDate);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(1);

        const withinDateRange =
          transactionDate >= fromDate && transactionDate <= endDate;

        // Check if transaction's originURL matches the selected originURL
        const matchesOriginURL = transaction.originURL === this.state.originURL;

        // Return true if both criteria are met
        return withinDateRange && matchesOriginURL;
      });
    }

    // Map the donations data to the new format
    const mappedDonations = donations.map(this.mapDonation);

    // Convert mapped data to worksheet
    const wsMapped = XLSX.utils.json_to_sheet(mappedDonations);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add the mapped data as a new worksheet
    XLSX.utils.book_append_sheet(wb, wsMapped, "Summary");

    // Add the original data
    const wsOriginal = XLSX.utils.json_to_sheet(donations);
    XLSX.utils.book_append_sheet(wb, wsOriginal, "Original Transactions");

    // Generate a downloadable file
    const filename = "transactions.xlsx";
    XLSX.writeFile(wb, filename);

    toast.success("Transactions downloaded!");
  };

  // Search for transactions based on criteria
  searchForTransactions = (event) => {
    event.preventDefault();
    // Perform validation for required fields (date range and origin URL)
    if (!this.state.fromDate || !this.state.toDate || !this.state.originURL) {
      this.setState({ error: "Please fill in all required fields" });
      return;
    }

    // Adjust the end date to include the full range of the selected day
    const endDate = new Date(this.state.toDate);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    // Filter transactions based on criteria
    const filteredTransactions = this.state.data.filter((transaction) => {
      // Check if transaction's createDate falls within the adjusted date range
      const transactionDate = new Date(transaction.createDate);

      const fromDate = new Date(this.state.fromDate);
      fromDate.setHours(0);
      fromDate.setMinutes(0);
      fromDate.setSeconds(1);

      const withinDateRange =
        transactionDate >= fromDate && transactionDate <= endDate;

      // Check if transaction's originURL matches the selected originURL
      const matchesOriginURL = transaction.originURL === this.state.originURL;

      // Return true if both criteria are met
      return withinDateRange && matchesOriginURL;
    });

    // Update state with filtered transactions
    this.setState({ filteredData: filteredTransactions }, () => {
      toast.success("Transactions searched!");
    });
  };

  filterTransactionsByIds = (ids) => {
    const filteredTransactions = this.state.data.filter((transaction) =>
      ids.includes(transaction.ID)
    );

    return filteredTransactions;
  };

  filterPayoutTransactions = (selectedPayoutID) => {
    let filteredTransactions = [];

    if (selectedPayoutID === "2024-03-07") {
      const ids = [
        "abe5d530-1637-49c1-abfd-2513a9e2b9f6",
        "f869a8ec-67a1-48d6-8e57-e6db03aba9f5",
      ];

      filteredTransactions = this.filterTransactionsByIds(ids);
    } else if (selectedPayoutID === "Monthly") {
      // Filter transactions with the word "monthly" in originURL
      filteredTransactions = this.state.data.filter((transaction) => {
        return (
          transaction.originURL &&
          transaction.originURL.toLowerCase().includes("monthly")
        );
      });
    } else if (selectedPayoutID === "Virtual Terminal") {
      // Filter transactions with the word "monthly" in originURL
      filteredTransactions = this.state.data.filter((transaction) => {
        return (
          transaction.originURL &&
          transaction.originURL.toLowerCase().includes("virtualterminal")
        );
      });
    } else {
      // Get the date a week before the selected payout ID
      const fromDate = new Date(selectedPayoutID);
      fromDate.setDate(fromDate.getDate() - 7);

      // Adjust the end date to include the full range of the selected day
      const toDate = new Date(selectedPayoutID);
      toDate.setHours(23);
      toDate.setMinutes(59);
      toDate.setSeconds(59);

      // Filter transactions based on the calculated date range and payout ID
      filteredTransactions = this.state.data.filter((transaction) => {
        const transactionDate = new Date(transaction.createDate);

        // Check if transaction's createDate falls within the date range
        const withinDateRange =
          transactionDate >= fromDate && transactionDate <= toDate;

        // Return true if both criteria are met
        return withinDateRange;
      });
    }

    return filteredTransactions;
  };

  listPayoutTransactions = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const selectedPayoutID = this.state.selectedPayoutID;

    const filteredTransactions =
      this.filterPayoutTransactions(selectedPayoutID);

    this.setState({ payoutData: filteredTransactions, isLoading: false });
  };

  handlePayoutIDChange = (e) => {
    e.preventDefault();
    const selectedPayoutID = e.target.value;

    // Assuming you have a state variable named 'selectedPayoutID' to store the selected ID
    this.setState({ selectedPayoutID: selectedPayoutID });
  };

  downloadPayoutTransactions = (e) => {
    e.preventDefault();
    const selectedPayoutID = this.state.selectedPayoutID;

    const filteredTransactions =
      this.filterPayoutTransactions(selectedPayoutID);

    // Map the donations data to the new format
    const mappedDonations = filteredTransactions.map(this.mapDonation);

    // Convert mapped data to worksheet
    const wsMapped = XLSX.utils.json_to_sheet(mappedDonations);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add the mapped data as a new worksheet
    XLSX.utils.book_append_sheet(wb, wsMapped, "Summary");

    // Add the original data
    const wsOriginal = XLSX.utils.json_to_sheet(filteredTransactions);
    XLSX.utils.book_append_sheet(wb, wsOriginal, "Original Transactions");

    // Generate a downloadable file
    const filename = "transactions.xlsx";
    XLSX.writeFile(wb, filename);

    toast.success("Transactions downloaded!");
  };

  exportData = (e) => {
    e.preventDefault();

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/stripe-payments`,
    }).then((response) => {
      const results = response.data;

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert attendees data to worksheet
      const ws = XLSX.utils.json_to_sheet(results);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Transactions");

      // Generate a unique filename for the Excel file
      const filename = `all_stripe_transactions_${new Date().toISOString()}.xlsx`;

      // Save the Excel file and trigger download
      XLSX.writeFile(wb, filename);
    });
  };

  render() {
    // Modify the JSX rendering to fit the Transactions component
    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <p>
              <button onClick={this.exportData} disabled={this.state.isLoading}>
                Export ALL Stripe Data As Excel
              </button>
            </p>
            <h1 className="main-ttl">
              <span>Search for Transactions</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.searchForTransactions} className="login">
                  <p className="contactform-field contactform-text">
                    <label htmlFor="fromDate" className="contactform-label">
                      From Date:
                    </label>
                    <span className="contactform-input">
                      <DatePicker
                        selected={this.state.fromDate}
                        onChange={(date) => this.handleFromDateChange(date)}
                        className="form-control date-picker"
                        placeholderText="From Date"
                      />
                    </span>
                  </p>
                  <p className="contactform-field contactform-text">
                    <label htmlFor="toDate" className="contactform-label">
                      To Date:
                    </label>
                    <span className="contactform-input">
                      <DatePicker
                        selected={this.state.toDate}
                        onChange={(date) => this.handleToDateChange(date)}
                        className="form-control date-picker"
                        placeholderText="To Date"
                      />
                    </span>
                  </p>
                  <p className="contactform-field contactform-text">
                    <label htmlFor="originURL" className="contactform-label">
                      Origin URL:
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="originURL"
                        as="select"
                        value={this.state.originURL}
                        onChange={this.handleOriginURLChange}
                        style={{ width: "80%" }}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Select Origin URL &#x2015;
                        </option>
                        {this.state.originURLs.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  <p className="auth-submit">
                    <input
                      type="submit"
                      value="Search Transactions"
                      disabled={this.state.isLoading}
                    />
                  </p>

                  <p className="auth-submit">
                    <button
                      onClick={this.downloadTransactions}
                      disabled={this.state.isLoading}
                    >
                      Download Transactions
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <TransactionList
            title="Search Results"
            transactionList={this.state.filteredData}
          />

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Get Transactions by Payout IDs</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Payout ID</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.selectedPayoutID}
                        onChange={this.handlePayoutIDChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.payoutIDList.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  <p>
                    <button
                      onClick={this.downloadPayoutTransactions}
                      disabled={this.state.isLoading}
                    >
                      Download Transactions
                    </button>
                  </p>

                  <p>
                    <button
                      onClick={this.listPayoutTransactions}
                      disabled={this.state.isLoading}
                    >
                      List Transactions
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          <TransactionList
            title="Transaction List"
            transactionList={this.state.payoutData}
          />
        </main>
        <ToastContainer />
        {this.state.isLoading && <Spinner />}
      </>
    );
  }
}

export default Transactions;
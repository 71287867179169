import React, { Component } from "react";

class UpdateReceiptForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      receiptInfo,
      isLoading,
      attendeeError,
      updateReceipt,
      handleReceiptInfoChange
    } = this.props;

    return (
      <div className="auth-col">
        <form onSubmit={updateReceipt} className="login">
          <p>
            <label htmlFor="Update_Receipt_ID">Receipt ID:</label>
            <input
              type="text"
              id="Update_Receipt_ID"
              name="Update_Receipt_ID"
              value={receiptInfo.ID}
              readOnly
              style={{ cursor: "not-allowed" }}
            />
          </p>
          <p>
            <label htmlFor="Update_Receipt_Number">Receipt Number:</label>
            <input
              type="text"
              id="Update_Receipt_Number"
              name="Update_Receipt_Number"
              value={receiptInfo.Receipt_Number}
              readOnly
              style={{ cursor: "not-allowed" }}
            />
          </p>
          <p>
            <label htmlFor="Update_Amount">Amount:</label>
            <input
              type="text"
              id="Update_Amount"
              name="Update_Amount"
              value={receiptInfo.Amount}
              readOnly
              style={{ cursor: "not-allowed" }}
            />
          </p>
          <p>
            <label htmlFor="Update_Salutation">Salutation:</label>
            <input
              type="text"
              id="Update_Salutation"
              name="Update_Salutation"
              value={receiptInfo.Salutation}
              onChange={(e) =>
                handleReceiptInfoChange("Salutation", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Organization_Name">Org Name:</label>
            <input
              type="text"
              id="Update_Organization_Name"
              name="Update_Organization_Name"
              value={receiptInfo.Organization_Name}
              onChange={(e) =>
                handleReceiptInfoChange("Organization_Name", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Addressee">Addressee:</label>
            <input
              type="text"
              id="Update_Addressee"
              name="Update_Addressee"
              value={receiptInfo.Addressee}
              onChange={(e) =>
                handleReceiptInfoChange("Addressee", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Address_1">Address Line 1:</label>
            <input
              type="text"
              id="Update_Address_1"
              name="Update_Address_1"
              value={receiptInfo.Address_Line_1}
              onChange={(e) =>
                handleReceiptInfoChange("Address_Line_1", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Address_2">Address Line 2:</label>
            <input
              type="text"
              id="Update_Address_2"
              name="Update_Address_2"
              value={receiptInfo.Address_Line_2}
              onChange={(e) =>
                handleReceiptInfoChange("Address_Line_2", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Address_3">Address Line 3:</label>
            <input
              type="text"
              id="Update_Address_3"
              name="Update_Address_3"
              value={receiptInfo.Address_Line_3}
              onChange={(e) =>
                handleReceiptInfoChange("Address_Line_3", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Address_4">Address Line 4:</label>
            <input
              type="text"
              id="Update_Address_4"
              name="Update_Address_4"
              value={receiptInfo.Address_Line_4}
              onChange={(e) =>
                handleReceiptInfoChange("Address_Line_4", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Address_5">Address Line 5:</label>
            <input
              type="text"
              id="Update_Address_5"
              name="Update_Address_5"
              value={receiptInfo.Address_Line_5}
              onChange={(e) =>
                handleReceiptInfoChange("Address_Line_5", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_City">City:</label>
            <input
              type="text"
              id="Update_City"
              name="Update_City"
              value={receiptInfo.City}
              onChange={(e) => handleReceiptInfoChange("City", e.target.value)}
            />
          </p>
          <p>
            <label htmlFor="Update_Province">Province:</label>
            <input
              type="text"
              id="Update_Province"
              name="Update_Province"
              value={receiptInfo.Province}
              onChange={(e) =>
                handleReceiptInfoChange("Province", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Postal_Code">Postal Code:</label>
            <input
              type="text"
              id="Update_Postal_Code"
              name="Update_Postal_Code"
              value={receiptInfo.Postal_Code}
              onChange={(e) =>
                handleReceiptInfoChange("Postal_Code", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Reference">Reference:</label>
            <input
              type="text"
              id="Update_Reference"
              name="Update_Reference"
              value={receiptInfo.Reference}
              onChange={(e) =>
                handleReceiptInfoChange("Reference", e.target.value)
              }
            />
          </p>
          <p>
            <label htmlFor="Update_Receipt_Date">Receipt Date:</label>
            <input
              type="text"
              id="Update_Receipt_Date"
              name="Update_Receipt_Date"
              value={receiptInfo.Todays_Date}
              onChange={(e) =>
                handleReceiptInfoChange("Todays_Date", e.target.value)
              }
            />
          </p>
          <p className="auth-submit">
            <button onClick={this.updateReceipt} disabled={isLoading}>
              Update Receipt
            </button>
          </p>
        </form>

        {attendeeError && (
          <div className="err404">
            <p className="err404-search">{attendeeError}</p>
          </div>
        )}
      </div>
    );
  }
}

export default UpdateReceiptForm;

import axios from "axios";

const generateLink = (fileName) => {
  // Convert the file name to a format suitable for the link
  const formattedFileName = fileName.replace(/\s+/g, "%20");

  // Generate the link using template literals
  const link = `https://batch-reports-pdf.s3.amazonaws.com/${formattedFileName}`;

  return link;
};

const get_report_data = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/report-data`
    );

    let batches = response.data;

    // Add a link to each object in the response
    batches = batches.map((batch) => ({
      ...batch,
      link: generateLink(batch.filename),
    }));

    batches.reverse();

    return batches;
  } catch (error) {
    console.log(error.message);
  }
};

const report_data = await get_report_data();

export default report_data;

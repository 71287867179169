const RECEIPTS = [
  { name: "Giving Tuesday", value: "GivingTuesday" },
  { name: "LAS Tickets (Soiree)", value: "Tickets" },
  { name: "LAS Wine Wall (Soiree)", value: "WineWall" },
  { name: "LAS Auction Donation (Soiree)", value: "AuctionDonation" },
  { name: "LAS SAM Donations (Soiree)", value: "SAMDonations" },
  { name: "SL Tickets (Spring Social)", value: "TicketsSL" },
  { name: "GJ VIP Tickets", value: "GJVIPTickets" },
  { name: "CCW 2023", value: "Construction2023" },
  { name: "Receipts for Week of 2024-01-08", value: "Week20240110" },
  {
    name: "Receipts for Week of 2024-01-15 to 2024-01-21",
    value: "Week20240115",
  },
  { name: "Monthly Donations 2023", value: "Monthly2023" },
  {
    name: "Receipts for Week of 2024-02-25 to 2024-03-01",
    value: "Week20240225",
  },
  { name: "Corrected Monthly Donations 2023", value: "Monthly2023Corrected" },
  { name: "Second Batch Monthly Donations 2023", value: "SecondMonthly2023" },
  {
    name: "Receipts for Week of 2024-03-11 to 2024-03-15",
    value: "Week20240311",
  },
  {
    name: "Receipts for Week of 2024-03-18 to 2024-03-22",
    value: "Week20240318",
  },
  {
    name: "Receipts for Week of 2024-04-01 to 2024-04-05",
    value: "Week20240401",
  },
  {
    name: "Receipts for Week of 2024-04-08 to 2024-04-12",
    value: "Week20240408",
  },
  {
    name: "Receipts for Week of 2024-04-15 to 2024-04-19",
    value: "Week20240415",
  },
  {
    name: "Receipts for Week of 2024-04-22 to 2024-04-26",
    value: "Week20240424",
  },
  {
    name: "Receipts for Week of 2024-04-29 to 2024-05-03",
    value: "Week20240429",
  },
  {
    name: "Receipts for GrapeJuice 2024 FAN",
    value: "GrapeJuice2024FAN",
  },
  {
    name: "Receipts for GrapeJuice 2024 Auction Donations",
    value: "GrapeJuice2024AucDon",
  },
  {
    name: "Receipts for GrapeJuice 2024 Auction Tickets",
    value: "GrapeJuice2024AucTickets",
  },
  {
    name: "Receipts for GrapeJuice 2024 Dinner Tickets",
    value: "GrapeJuice2024Tickets",
  },
  {
    name: "Receipts for Week of 2024-05-13 to 2024-05-17",
    value: "Week20240513",
  },
  {
    name: "Receipts for Week of 2024-05-20 to 2024-05-24",
    value: "Week20240520",
  },
  {
    name: "Receipts for Week of 2024-06-03 to 2024-06-09",
    value: "Week20240603",
  },
  {
    name: "Receipts for GrapeJuice 2024 Auction Purchases",
    value: "GrapeJuice2024AucPur",
  },
  {
    name: "Receipts for Week of 2024-06-10 to 2024-06-16",
    value: "Week20240610",
  },
  {
    name: "Receipts for Week of 2024-06-17 to 2024-06-23",
    value: "Week20240617",
  },
  {
    name: "Receipts for Week of 2024-06-24 to 2024-06-30",
    value: "Week20240624",
  },
  {
    name: "BMO Marathon March, April, May, 2024",
    value: "BMO0304052024",
  },
  {
    name: "Receipts for Week of 2024-07-01 to 2024-07-07",
    value: "Week20240701",
  },
  {
    name: "Receipts for Week of 2024-07-08 to 2024-07-14",
    value: "Week20240708",
  },
  {
    name: "Receipts for Week of 2024-07-15 to 2024-07-21",
    value: "Week20240715",
  },
  {
    name: "Receipts for Week of 2024-07-22 to 2024-07-28",
    value: "Week20240722",
  },
  {
    name: "Receipts for Week of 2024-07-29 to 2024-08-04",
    value: "Week20240729",
  },
  {
    name: "Receipts for Week of 2024-08-05 to 2024-08-11",
    value: "Week20240805",
  },
  {
    name: "Receipts for Week of 2024-08-12 to 2024-08-18",
    value: "Week20240812",
  },
  {
    name: "Soiree 2024 Auction Donations",
    value: "Soiree2024AucDon",
  },
  {
    name: "Receipts for Week of 2024-08-19 to 2024-08-25",
    value: "Week20240819",
  },
  {
    name: "Receipts for Week of 2024-08-26 to 2024-09-01",
    value: "Week20240826",
  },
  {
    name: "Mail Outs",
    value: "20240830MailOuts",
    secret: true
  },
  {
    name: "Receipts for Week of 2024-09-02 to 2024-09-08",
    value: "Week20240902",
  },
  {
    name: "Receipts for Week of 2024-09-09 to 2024-09-15",
    value: "Week20240909",
  },
  {
    name: "Receipts for Week of 2024-09-16 to 2024-09-22",
    value: "Week20240916",
  },
];

export default RECEIPTS;